import React, { ReactElement, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

interface AppContainerProps {
  children: ReactNode
}

export default function AppContainer({ children }: AppContainerProps): ReactElement {
  return (
    <BrowserRouter>
      {children}
    </BrowserRouter>
  );
}
