import React, { Component, ReactNode } from 'react';
// @ts-ignore
import { Error } from '@devsta/common-react';
import { withScope, captureException, showReportDialog } from '@sentry/browser';

interface ErrorBoundaryState {
  error?: Error
}

interface ErrorBoundaryProps {
  children: ReactNode
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: any): void {
    this.setState({ error });

    withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      captureException(error);
    });
  }

  render(): ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    if (!error) { return children; }

    return <Error onSubmitError={showReportDialog} />;
  }
}
