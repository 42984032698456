export default {
  sentryDSN: 'https://6e13eaaa5e984ec4a8d450bb636e45d3@sentry.io/1293269',
  sentryReleasePrefix: 'defaultp@',
  apiEndpoint: 'https://api-defaultp.production.shootsta.com/',
  serviceEndpoint: 'https://service-defaultp.production.shootsta.com/',
  subscriptionsEndpoint: 'wss://service-defaultp.production.shootsta.com/subscriptions',
  ssoApiEndpoint: 'https://sso.shootsta.com/saml',
  shootstaDomainSegments: 'develop,staging,app',
  skipNotifications: 'true'
};
