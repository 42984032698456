import React, { ReactElement } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  NotFound as PureNotFound,
  NotificationsProvider
  // @ts-ignore
} from '@devsta/common-react';

// Sub Apps
// @ts-ignore
import Finance from 'adminclient-finance';

// Containers & Layouts
import { ErrorBoundary } from '../common/containers';

import { mapRoutes } from '../utils';

const NotFound = () => <PureNotFound />;

const financeRoutes = mapRoutes(Finance);

/**
 * Creates the application route components
 *
 * @return {Element}
 */
export default function createRoutes(): ReactElement {
  return (
    <ErrorBoundary>
      <NotificationsProvider>
        <Switch>
          <Redirect exact from="/" to="/finance/invoice/pay" />
          {financeRoutes}
          <Route render={NotFound} />
        </Switch>
      </NotificationsProvider>
    </ErrorBoundary>
  );
}
