import { init } from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import isElectron from 'is-electron';
import { ApolloProvider } from 'react-apollo';
// @ts-ignore
import globalConfig from 'react-global-configuration';
import 'what-input';
// @ts-ignore
import { apiClient } from '@devsta/client-auth';
import { AppContainer } from './common/containers';
import Routes from './routes';
import config from './config';
import { version } from '../package.json';
import './styles/_main.scss';

if (config.sentryDSN && !isElectron()) {
  init({ dsn: config.sentryDSN, release: `${config.sentryReleasePrefix}${version}` });
}

globalConfig.set({});

const root = document.getElementById('root');

const App = () => (
  <ApolloProvider client={apiClient({ apiEndpoint: config.apiEndpoint })}>
    <AppContainer>
      <Routes />
    </AppContainer>
  </ApolloProvider>
);

if (root != null) {
  ReactDOM.render(<App />, root);
}
